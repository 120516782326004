<template>
    <div>
        <b-card no-body class="card-app-design mb-0 bb-lrr-0">
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Date Interval" label-for="dateInterval">
                            <flat-pickr v-model="searchIntervalDateRepo"
                                        :config="{ mode: 'range',defaultDate:[searchReportDiffMonthDate,searchReportDate]}"
                                        class="form-control" placeholder="YYYY-MM-DD | YYYY-MM-DD"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Team" label-for="team">
                            <v-select v-if="teamList"
                                      :options="teamList" :reduce="(option) => option.value"
                                      v-model="teamFilter"
                                      placeholder="Select Team" class="w-100"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="8 my-auto" v-if="bitbucketRepo">
                        <div class="design-planning-wrapper">
                            <div class="design-planning mb-0">
                                <p class="card-text mb-25">
                                    Repositories
                                </p>
                                <h6 class="mb-0">
                                    {{ bitbucketRepo.data.total_repo }}
                                </h6>
                            </div>
                            <div class="design-planning mb-0">
                                <p class="card-text mb-25">
                                    Total Commit
                                </p>
                                <h6 class="mb-0">
                                    {{ bitbucketRepo.data.total_commit }}
                                </h6>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card no-body id="guruTableScroll" class="mb-0 mt-0" v-if="!isLoading">
            <div class="guru" v-if="bitbucketRepo">
                <div class="guru_row guru_row-hours sticky"
                     :style="'grid-template-columns: 150px repeat('+bitbucketRepo.data.grid+', 1fr);'">
                    <div class="guru_row-first  d-flex  align-items-center">Name</div>
                    <span v-for="date in bitbucketRepo.data.periods" :key="date.id">{{ formatDate(date) }}</span>
                </div>
                <div class="guru_row guru_row-lines"
                     :style="'grid-template-columns: 150px repeat('+bitbucketRepo.data.grid+', 1fr);'">
                    <span></span>
                    <span v-for="date in bitbucketRepo.data.periods" :key="date.id"></span>
                </div>
                <div class="guru_row" v-for="repository in bitbucketRepo.data.repositories" :key="repository.id"
                     ref="refDiscordUsers">
                    <div class="guru_row-first">
                        <b-media vertical-align="center">
                            <b-link class="font-weight-bold d-block text-nowrap">
                                {{ repository.repository }}
                            </b-link>
                            <small class="text-muted">Total: {{ repository.total_commit }}</small>
                        </b-media>
                    </div>
                    <ul class="guru_row-bars p-0"
                        :style="'grid-template-columns: repeat('+bitbucketRepo.data.grid+', 1fr)!important;'">
                        <li v-for="(commit,index) in repository.commits.filter(item => item.count > 0)" :key="index"
                            class="m-20 justify-content-center"
                            @click="commit.count>0?getDetailInfo(repository,commit.date):null"
                            :style="columnFormat(commit.key,commit.count,repository.total_commit)"
                            v-b-tooltip.html.hover.top="'Commit: '+commit.count+'<br/>Date: '+commit.date">
                            {{ commit.count }}
                        </li>
                    </ul>
                </div>
            </div>
            <bitbucket-repository-detail ref="modal" :modalDate="modalDate"></bitbucket-repository-detail>
        </b-card>
        <b-card v-if="isLoading" class="mt-2">
            <div class="text-center my-2">
                <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </b-card>
        <b-card v-if="!isLoading && !bitbucketRepo" class="mt-2">
            <div class="d-flex align-items-center">
                <strong>Not Found.</strong>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BOverlay, BFormGroup, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {avatarText} from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import useBitbucketApi from '@/composables/useBitbucketApi'
import moment from 'moment'
import BitbucketRepositoryDetail from '@/components/BitbucketRepositoryDetail.vue'

export default {
    components: {
        vSelect,
        BFormGroup,
        BSpinner,
        BOverlay,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        flatPickr,
        BitbucketRepositoryDetail
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            modalDate: null,
        }
    },
    setup() {
        const {
            isLoading,
            searchReportDate,
            searchIntervalDateRepo,
            searchReportDiffMonthDate,
            modalLoading,
            fetchBitbucketRepo,
            bitbucketRepo,
            fetchBitbucketRepositoryDetail,

            teamFilter,
            teamList,
            fetchTeamList,
        } = useBitbucketApi()
        return {
            isLoading,
            searchReportDate,
            searchIntervalDateRepo,
            searchReportDiffMonthDate,
            avatarText,
            modalLoading,
            fetchBitbucketRepo,
            bitbucketRepo,
            fetchBitbucketRepositoryDetail,

            teamFilter,
            teamList,
            fetchTeamList,
        }
    },
    mounted() {
        this.fetchBitbucketRepo()
        this.fetchTeamList()
    },
    methods: {
        formatDate(date) {
            return moment(date).format('DD.MM')
        },
        columnFormat(key, count, total) {
            if (count == 0) return 'grid-column:' + key + ';background:none'
            let normalize = (count - 0) / (10 - 0)
            let colorText = normalize < 0.5 ? "#232323" : '#f7f7f7'
            let background = `rgb(9, 191, 44, ${normalize})`;
            return `grid-column:${key};color:${colorText};background:${background}`
        },
        getDetailInfo(repository, date) {
            this.modalDate = date
            this.fetchBitbucketRepositoryDetail(repository.id, date)
            this.$root.$emit("bv::show::modal", "modal-primary")
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.tooltip-inner {
    text-align: left;
}
</style>
