// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line object-curly-newline
import {ref, computed, watch, onMounted} from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'

export default function useBitbucketApi() {
    // Use toast
    const toast = useToast()
    const bitbucketUsers = ref(null)
    const isLoading = ref(true)
    const refbitbucketUsers = ref(null)
    const searchReportDate = ref(moment().format('YYYY-MM-DD'))
    const searchReportDiffMonthDate = ref(moment().subtract(2, 'weeks').format('YYYY-MM-DD'))
    const searchIntervalDate = ref(null)
    const searchIntervalDateRepo = ref(null)
    const bitbucketRepo = ref(null)
    const teamFilter = ref(null)
    const teamFilterUser = ref(null)
    const teamList = ref(null)
    const roleFilter = ref('Developer')
    const roleOptions = [
        {label: 'All', value: 0},
        {label: 'Developer', value: 'Developer'},
        {label: '3D Artist', value: '3D Artist'},
    ]

    // ------------------------------------------------
    // Get Bitbucket Users
    // ------------------------------------------------
    watch([searchIntervalDate, roleFilter, teamFilterUser], () => {
        isLoading.value = false
        fetchBitbucketUsers()
    })

    const fetchBitbucketUsers = (ctx, callback) => {
        const customdate = searchIntervalDate.value ? searchIntervalDate.value.split('to') : [searchReportDiffMonthDate.value, searchReportDate.value]
        if (customdate.length != 2) return
        if (dateControl(customdate[1], customdate[0])) return
        isLoading.value = true
        store
            .dispatch('bitbucketStore/fetchBitbucketUsers', {
                team: teamFilterUser.value,
                role: roleFilter.value != 0 ? roleFilter.value : ["Developer", "3D Artist"],
                start_date: customdate[0],
                end_date: customdate[1],
            })
            .then(response => {
                bitbucketUsers.value = response.data
                isLoading.value = false
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    const fetchBitbucketUserDetail = (id, date) => {
        store
            .dispatch('bitbucketStore/fetchUserDetailView', {
                id: id,
                date: date,
            })
            .then(response => response.data)
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    // ------------------------------------------------
    // Get Bitbucket Repositories
    // ------------------------------------------------
    watch([searchIntervalDateRepo, teamFilter], () => {
        isLoading.value = false
        fetchBitbucketRepo()
    })
    const fetchBitbucketRepo = (ctx, callback) => {
        const customdate = searchIntervalDateRepo.value ? searchIntervalDateRepo.value.split('to') : [searchReportDiffMonthDate.value, searchReportDate.value]
        if (customdate.length != 2) return
        if (dateControl(customdate[1], customdate[0])) return
        isLoading.value = true
        store
            .dispatch('bitbucketStore/fetchBitbucketRepo', {
                team: teamFilter.value,
                start_date: customdate[0],
                end_date: customdate[1],
            })
            .then(response => {
                bitbucketRepo.value = response.data
                isLoading.value = false
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    const fetchBitbucketRepositoryDetail = (id, date) => {
        store
            .dispatch('bitbucketStore/fetchRepositoryDetailView', {
                id: id,
                date: date,
            })
            .then(response => response.data)
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    const dateControl = (start, end) => {
        let maxDiffDay = moment(start).diff(moment(end), 'days')
        if (maxDiffDay > 31) {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Info',
                    icon: 'InfoIcon',
                    variant: 'info',
                    text: 'The date range must be a maximum of 1 month.',
                },
            })
            return true
        }
        return false
    }

    // ------------------------------------------------
    // Get Team List
    // ------------------------------------------------
    const fetchTeamList = (ctx, callback) => {
        store
            .dispatch('teamStore/fetchTeams')
            .then(response => {
                const {data} = response.data
                teamList.value = data.map(item => ({value: item.id, label: item.name}))
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    return {
        bitbucketUsers,
        fetchBitbucketUsers,
        isLoading,
        refbitbucketUsers,
        searchReportDate,
        searchIntervalDate,
        searchReportDiffMonthDate,
        fetchBitbucketRepo,
        bitbucketRepo,
        fetchBitbucketUserDetail,
        searchIntervalDateRepo,
        fetchBitbucketRepositoryDetail,
        roleOptions,
        roleFilter,

        teamFilterUser,
        teamFilter,
        teamList,
        fetchTeamList,
    }
}
